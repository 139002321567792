$fa-font-path: "../fonts";
@import "@fortawesome/fontawesome-pro/scss/fontawesome";
@import "@fortawesome/fontawesome-pro/scss/solid";
@import "@fortawesome/fontawesome-pro/scss/regular";
@import "@fortawesome/fontawesome-pro/scss/light";
@import "@fortawesome/fontawesome-pro/scss/brands";/**
 * Handles all font loading separate from main theme so that fonts are not
 * render-blocking. This file is loaded separately from theme.css!
 *
 * NOTE: This file must exist and the font families chosen in themeconfig.json
 * will be loaded automatically by the build process.
 *
 * Place @font-face directives at the top of this file
 */

// Default font style, mostly used for icons baked into the templates
.fa {
	// Font Awesome defaults to fa = fas
	//@extend .far;
}

.fa, .fas, .far, .fal, .fab {

	// While fonts are loading
	.wf-loading & {
		// Prevent FOUT
		color: transparent;
	}
}
